import React from "react";
import ReactTooltip from "react-tooltip";
import ReactTable from "react-table";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Tooltip,
  IconButton,
  Button,
  ButtonGroup,
  Grid,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import api from "../utils/api";
// import { showTooltipInfo } from "../Common/modal/action/showTooltipInfo";
// import "./Insert.css";
import moment from "moment";
import { showTooltipInfo } from "./Common/modal/action/showTooltipInfo";
import ConfirmModel from "./Common/modal/ConfirmModal/ConfirmModel";
import MicOffIcon from "@material-ui/icons/MicOff";
import MicIcon from "@material-ui/icons/Mic";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import {
  listenFirebase,
  unsubscrible,
  addFirebaseData,
} from "../utils/firebase";

export default class FastRecordTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: moment().startOf("date").format("YYYY-MM-DD") + " 00:00:00",
      to: moment().endOf("date").format("YYYY-MM-DD") + " 23:59:59",
      selectList: [],
      record: [],
      showConfirmDialog: false,
      showDeleteDialog: false,
      targetId: "",
      selectedIndex: -1,
    };
  }
  componentDidMount() {
    this.onGetFastRecord();
    const { status } = this.props;
    if (status === "pending") {
      // listen
      listenFirebase("triggerPostRecord", "approvingStep", (data) => {
        if (data.changed) {
          this.onGetFastRecord("pending");
        }
      });
    } else if (status === "accepted") {
      listenFirebase("triggerPostRecord", "readingStep", (data) => {
        if (data.changed) {
          this.onGetFastRecord("accepted");
        }
      });
    }
  }
  onGetFastRecord = (param) => {
    const { from, to } = this.state;
    const { status } = this.props;
    api
      .getFastRecord({ page: 0, size: 100, status, from, to })
      .then((response) => {
        if (response.success) {
          this.setState({ record: response.data.content });
          if (param) {
            if (param === "pending") {
              addFirebaseData("triggerPostRecord", "approvingStep", {
                changed: false,
              });
            } else {
              addFirebaseData("triggerPostRecord", "readingStep", {
                changed: false,
              });
            }
          }
        }
      });
  };
  onChangeStatusRecords = (status, recordId) => {
    const { targetId } = this.state;
    let id = targetId;
    if (recordId) {
      id = recordId;
    }
    if (!id) return;
    api.putFastRecord({ recordId: id, status }).then((response) => {
      console.log("PUT FAST RECORD");
      if (response.success) {
        this.onGetFastRecord();
      }
    });
  };
  columns = [
    {
      Header: () => {
        let numSelected = this.state.selectList.length;
        let rowCount = this.state.record.length;
        return (
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={this.handleSelectAllClick}
            color="primary"
            size="small"
          />
        );
      },
      accessor: "id",
      Cell: (props) => {
        console.log("PROPS", props);
        return (
          <Checkbox
            checked={this.isSelected(props.value)}
            color="primary"
            size="small"
          />
        );
      },
      className: "insert-box-content",
      maxWidth: 50,
    },
    {
      Header: () => <strong>TÙY CHỌN</strong>,
      accessor: "id",
      className: "insert-box-content insert-box-action",
      maxWidth: 125,
      minWidth: 125,
      Cell: (props) => {
        let id = props.value;
        return (
          <div>
            <ReactTooltip
              place="left"
              id={id}
              effect="solid"
              className="tooltipInfoRecord"
            >
              {showTooltipInfo(props.original)}
            </ReactTooltip>

            <ButtonGroup size="small" aria-label="small outlined button group">
              {/* <Tooltip title=" Chỉnh sửa">
                <IconButton
                  aria-label="modify"
                  size="small"
                  color="primary"
                  onClick={() => {
                    let idList = {
                      personSharing: props.original.personSharing,
                      addresses: props.original.address,
                      reasons: props.original.reason,
                      speeds: props.original.speed,
                    };

                    let inputForForm = {
                      personSharing: props.original.personSharing.name,
                      phoneNumber: props.original.personSharing.phoneNumber,
                      addresses: props.original.address.name,
                      direction: props.original.address.direction,
                      reasons: props.original.reason.name,
                      speeds: props.original.speed,
                      notice: props.original.notice,
                      district: [],
                    };

                    let recordId = props.original.id;
                    this.setState({
                      showModifyDialog: true,
                      showIndex: props.index,
                      currentInfoForm: inputForForm,
                      idList,
                      recordId,
                    });
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip> */}
              {this.props.status === "accepted" ? (
                <>
                  <Tooltip title="Bỏ qua tin">
                    <IconButton
                      aria-label="uncheck"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        this.setState({ targetId: id });
                        this.onChangeStatusRecords("unread", id);
                        // this.handleSwapDialog("unread");
                      }}
                    >
                      <MicOffIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Đọc tin">
                    <IconButton
                      aria-label="read"
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.setState({ targetId: id });
                        this.onChangeStatusRecords("read", id);
                        // this.handleSwapDialog("read");
                      }}
                    >
                      <MicIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Rút tin">
                    <IconButton
                      aria-label="correct"
                      size="small"
                      color="primary"
                      onClick={() => {
                        this.setState({ currentSelectId: id });
                        this.onChangeStatusRecords("pending", id);
                      }}
                    >
                      <SettingsBackupRestoreIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Xóa tin">
                    <IconButton
                      aria-label="delete"
                      size="small"
                      color="secondary"
                      onClick={() =>
                        this.setState({ showDeleteDialog: true, targetId: id })
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Xác nhận tin">
                    <IconButton
                      aria-label="check"
                      size="small"
                      style={{ color: "#4caf50" }}
                      onClick={() =>
                        this.setState({ showConfirmDialog: true, targetId: id })
                      }
                    >
                      <CheckBoxIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </ButtonGroup>
          </div>
        );
      },
    },
    {
      Header: () => <strong>NGƯỜI CHIA SẺ</strong>,
      Cell: (props) => {
        let record = props.original;
        let sharer = record.personSharing ? record.personSharing.name : "";
        let phone = record.personSharing
          ? record.personSharing.phoneNumber
          : "";
        return (
          <span data-tip data-for={record.id}>
            {sharer} - {phone}
          </span>
        );
      },
      className: "insert-box-content",
    },
    {
      Header: () => <strong>ĐỊA ĐIỂM</strong>,
      accessor: "address",
      Cell: (props) => {
        // let address = props.value
        //   ? props.value.name +
        //     (props.value.direction ? " HƯỚNG " + props.value.direction : "")
        //   : "";
        return (
          <span data-tip data-for={props.original.id}>
            {props.value}
          </span>
        );
      },
      minWidth: 150,
      className: "insert-box-content",
    },
    {
      Header: () => <strong>VẬN TỐC</strong>,
      accessor: "speed",
      Cell: (props) => {
        // let speed = props.value ? props.value.name : "";
        const value = props.value;
        let speedText = "";
        if (value <= 5) {
          speedText = "Ùn tắc";
        } else if (value > 5 && value <= 15) {
          speedText = "Xe đông di chuyển khó khăn";
        } else if (value > 15 && value <= 25) {
          speedText = "Xe đông di chuyển chậm";
        } else if (value > 25 && value <= 35) {
          speedText = "Xe đông di chuyển ổn định";
        } else {
          speedText = "Thông thoáng";
        }
        return (
          <span data-tip data-for={props.original.id}>
            {speedText}
          </span>
        );
      },
      className: "insert-box-content",
    },
    // {
    //   Header: () => <strong>GHI CHÚ</strong>,
    //   accessor: "notice",
    //   Cell: (props) => {
    //     let notice = props.value ? props.value : "";
    //     return (
    //       <span data-tip data-for={props.original.id}>
    //         {notice}
    //       </span>
    //     );
    //   },
    //   className: "insert-box-content",
    // },
  ];

  isSelected = (id) => this.state.selectList.indexOf(id) !== -1;
  handleChange = (event, id) => {
    let selected = this.state.selectList;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selectList: newSelected });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let newSelected = this.state.record.map((n) => n.id);
      this.setState({ selectList: newSelected });
      return;
    }
    this.setState({ selectList: [] });
  };

  clearSelectList = () => this.setState({ selectList: [] });

  handleSwapDeleteDialog = () => {
    this.setState({ showDeleteDialog: !this.state.showDeleteDialog });
  };

  handleSwapConfirmDialog = () => {
    this.setState({ showConfirmDialog: !this.state.showConfirmDialog });
  };

  render() {
    return (
      // <showTooltipInfo
      // <showTooltipInfo
      <>
        <ReactTable
          className="-striped -highlight"
          data={this.state.record}
          pageSize={20}
          columns={this.columns}
          // PaginationComponent={TablePagination}
          // showPagination={false}
          resizable={false}
          sortable={false}
          getTrProps={(state, rowInfo) => ({
            onClick: (event) => {
              this.handleChange(event, rowInfo.original.id);
            },
          })}
          style={{
            height: "94vh",
          }}
        />
        <ConfirmModel
          label={"xác nhận"}
          swapFunc={this.handleSwapConfirmDialog}
          showDialog={this.state.showConfirmDialog}
          confirmFunction={() => {
            this.clearSelectList();
            this.handleSwapConfirmDialog();
            this.onChangeStatusRecords("accepted");
          }}
        />
        <ConfirmModel
          label={"xóa"}
          swapFunc={this.handleSwapDeleteDialog}
          showDialog={this.state.showDeleteDialog}
          deleteConfirm={true}
          confirmFunction={(removeReason) => {
            this.clearSelectList();
            this.handleSwapDeleteDialog();
            this.onChangeStatusRecords("removed");
          }}
        />
      </>
    );
  }
}
