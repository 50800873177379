import React from 'react';
import "./Home.css"

export default class Home extends React.Component{
	render(){
		return(
			<div className="cover-container d-flex h-100 p-3 mx-auto flex-column">
				<div className="masthead mb-5">
					<div className="inner">
						
					</div>
				</div>
				<div className="inner home-content">
					<h1 className="cover-heading pb-3">Kênh giao thông đô thị VOH</h1>
					<p className="lead lead-content">Hệ thống hỗ trợ Kênh giao thông đô thị của VOH trên sóng FM 95.6 MHz</p>
					<p className="lead lead-content">Ghi nhận, xử lý, tổng hợp bản tin giao thông tại TP. Hồ Chí Minh và các khu vực lân cận</p>
					<p className="lead lead-content">Sản phẩm được thực hiện bởi nhóm nghiên cứu từ Khoa KH&KT Máy Tính - Trường Đại Học Bách Khoa TP. HCM</p>
					<p className="lead mt-4">
					</p>
				</div>
				<div className="mastfoot mt-auto">
					<div>
						<p>Thông tin liên hệ kênh giao thông <a 
						href="https://voh.com.vn/radio/fm-95.6-mhz-3.html">VOH 95.6 mhz</a> và Đại học <a 
						href="http://www.hcmut.edu.vn/vi">Bách Khoa</a> Hồ Chí Minh.</p>
					</div>
				</div>
			</div>
		)
	}
}