import React from "react";
import CurrentRecords from "../Type/CurrentTable";
import Messages from "../../../Common/messages/Messages";
import { Tabs } from "antd";
import FastRecordTable from "../../../FastRecordTable";

export default class ReadSolveTable extends React.Component {
  constructor(props) {
    super(props);
    let trafficState = localStorage.getItem("trafficState");
    let user = null;
    if (trafficState) {
      user = JSON.parse(trafficState).user;
    }
    this.state = {
      top: false,
      user,
    };
  }
  render() {
    return (
      <div>
        <Tabs
          style={{
            background: "#fff",
            paddingTop: "30px",
            paddingLeft: "10px",
          }}
          defaultActiveKey="voh"
        >
          <Tabs.TabPane key="voh" tab="Bản tin Voh">
            <CurrentRecords user={this.state.user} />
            <CurrentRecords status="solved" user={this.state.user} />
          </Tabs.TabPane>
          <Tabs.TabPane key="fast-record" tab="Bản tin nhanh">
            <FastRecordTable status="accepted" />
          </Tabs.TabPane>
        </Tabs>

        <Messages user={this.state.user} />
      </div>
    );
  }
}
