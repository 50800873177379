import * as firebase from "firebase";

const config = {
  apiKey: "AIzaSyA9OkvT8DwwO-fZzngrPH_wjMMqBdzTLKk",
  authDomain: "voh-record.firebaseapp.com",
  databaseURL: "https://voh-record.firebaseio.com",
  projectId: "voh-record",
  storageBucket: "voh-record.appspot.com",
  messagingSenderId: "705796826239",
};

firebase.initializeApp(config);

let db = firebase.firestore();

export const addFirebaseData = (collectionName, docName, data) => {
  db.collection(collectionName)
    .doc(docName)
    .set(data)
    .then((docRef) => {
      console.log("DOCUMENT WRITTEN WITH ID", docRef.id);
    })
    .catch((error) => {
      console.log("FIRE BASE ERROR", error);
    });
};

let listening = null;

export const listenFirebase = (collectionName, docName, cb) => {
  listening = db
    .collection(collectionName)
    .doc(docName)
    .onSnapshot((doc) => {
      console.log("CURRENT FIRE BASE DATA", doc.data());
      if (cb) {
        cb(doc.data());
      }
    });
};

export const unsubscrible = () => {
  if (listening) listening();
};

export default firebase;
