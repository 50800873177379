import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {authoUser} from "../constants/authoUser";
import { Button } from '@material-ui/core';
import "./Navigation.css";
import ConfirmModel from "../modal/ConfirmModal/ConfirmModel";

export default class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: true,
			isFirstAccess:true,
			user: this.props.user,
			currentPage: this.props.currentPage,
			confirmLogoutModel:false,
		};
		this.toggleNavbar = this.toggleNavbar.bind(this);
	}
	componentWillReceiveProps(nextProps, nextContext) {
		if(nextProps.user !== this.state.user){
			this.setState({ user:nextProps.user});
		}
		if(nextProps.currentPage !== this.state.currentPage){
			console.log("currentPage: ",nextProps.currentPage);
			this.setState({ currentPage:nextProps.currentPage});
		}
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	logOut = () => {
		if (this.props.onChange) {
			this.props.onChange('logout');
		}
	};

	handleSwapLogoutDialog = () => {
		this.setState({confirmLogoutModel: !this.state.confirmLogoutModel});
	};

	showRole(role){
		if(role === "ROLE_ADMIN")
			return "Quản lý";
		else if(role === "ROLE_EDITOR")
			return "Biên tập viên";
		else if(role === "ROLE_DATAENTRY")
			return "Thư ký";
		else if(role === "ROLE_DATAENTRY_EDITOR")
			return "Thư ký kiêm BTV";
		else
			return "MC";
	}

	render() {
		let { user } = this.state;
		let itemsRender = [];
		let listItemAuths = authoUser[user.role];
		if(listItemAuths){
			listItemAuths.forEach((item, index) => {
				itemsRender.push(
					<Nav.Item key={index}>
						<Nav.Link onSelect={() =>{this.setState({isFirstAccess:false})}}
						          as={Link} to={item.path}  eventKey={item.name}>{item.name}</Nav.Link>
					</Nav.Item>
				)
			});
		}

		return (
			<Navbar className="navbar navbar-expand-lg navbar-container" style={{ boxShadow: '1px 1px 20px' }} >
				{this.state.currentPage==="404"?"":
					<div className="collapse navbar-collapse" id="navbar-pages">
						<Nav variant="pills" defaultActiveKey="/VohReport/home" className="navbar-nav mr-auto mt-2 mt-lg-0">
							<Nav.Item >
								<Nav.Link onSelect={() =>{this.setState({isFirstAccess:false})}}
								          as={Link}  to="/VohReport/home"
								          eventKey="/VohReport/home/" >TRANG CHỦ</Nav.Link>
							</Nav.Item>
							{itemsRender}
						</Nav>
						<span className="user-title role-title">
							Xin chào {this.showRole(this.state.user?this.state.user.role:'')}
						</span>
						<span className="user-title">
							<strong>{this.state.user ? this.state.user.name : 'Guest'}</strong>
						</span>
						<Button
							onClick={this.handleSwapLogoutDialog}
							size="small"
							id="log-out-btn"
						>Đăng xuất</Button>
					</div>
				}
				<ConfirmModel
					label={"đăng xuất"}
					swapFunc={this.handleSwapLogoutDialog}
					showDialog={this.state.confirmLogoutModel}
					confirmFunction={()=>{
						this.handleSwapLogoutDialog();
						this.logOut();
					}}
				/>
			</Navbar>
		);
	}
}
